import React from "react";

function StatisticBox({ boxName, icon, title, text_0, text_1 }) {
  return (
    <div className={`statistic-box ${boxName}`}>
      <div className="top">
        <div className="box-icon">{icon}</div>
        <h5 className="box-title">{title}</h5>
      </div>
      <p className="box-text">
        {text_0}
        <br />
        {text_1}
      </p>
    </div>
  );
}

export default StatisticBox;
