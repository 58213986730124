import React, { useEffect } from "react";
import Top from "./Top/Top";
import { useTranslation } from "react-i18next";

import ToolsWeUse from "./ToolsWeUse/ToolsWeUse";
import MainFeatures from "./MainFeatures/MainFeatures";
import ServicesOne from "./ServicesOne/ServicesOne";
import ServicesTwo from "./ServicesTwo/ServicesTwo";
import ContactUs from "./ContactUs/ContactUs";

function Home({ setOpenContact, setOpenRequest }) {
  const [t] = useTranslation();
  useEffect(() => {
    document.title = t("GoUp - Home");
  }, [t]);

  return (
    <div>
      <Top setOpenRequest={setOpenRequest} />
      <ToolsWeUse />
      <MainFeatures />
      <ServicesOne />
      <ServicesTwo />
      <ContactUs setOpenContact={setOpenContact} />
    </div>
  );
}

export default Home;
