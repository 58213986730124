import React from "react";
import Intergration from "./Intergration";
import {
  FaWordpress,
  FaShopify,
  FaMailchimp,
  FaPinterest,
} from "react-icons/fa";
import {
  BsMeta,
  BsGoogle,
  BsMicrosoft,
  BsGithub,
  BsFillDatabaseFill,
} from "react-icons/bs";
import { SiAdobe, SiMicrosoftoffice } from "react-icons/si";
import { TbSeo } from "react-icons/tb";
import { useTranslation } from "react-i18next";
function ToolsWeUse() {
  const [t] = useTranslation();

  return (
    <div className="tool-we-use" id="tool-we-use">
      <div className="container">
        <h1 className="title">{t("Integrations")}</h1>
        <p className="text">
          {t("We have merged with the largest companies in")} <br />
          {t("marketing in the world and branched out in many fields")} <br />
          {t("with long-term contracts.")}
        </p>
        <div className="box-integrations">
          <Intergration icon={<BsMeta />} duration={1000} />
          <Intergration icon={<BsGoogle />} duration={1100} />
          <Intergration icon={<FaPinterest />} duration={1200} />
          <Intergration icon={<BsMicrosoft />} duration={1300} />
          <Intergration icon={<FaWordpress />} duration={1400} />
          <Intergration icon={<FaShopify />} duration={1500} />
          <Intergration icon={<FaMailchimp />} duration={1600} />
          <Intergration icon={<BsGithub />} duration={1700} />
          <Intergration icon={<SiAdobe />} duration={1800} />
          <Intergration icon={<SiMicrosoftoffice />} duration={1900} />
          <Intergration icon={<TbSeo />} duration={2000} />
          <Intergration icon={<BsFillDatabaseFill />} duration={2100} />
        </div>
      </div>
    </div>
  );
}

export default ToolsWeUse;
