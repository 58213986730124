import React, { useEffect, useLayoutEffect, useState } from "react";
import logo from "../../Assets/img/logo-250.png";
import NavList from "./NavList";
import NavRight from "./NavRight";
import { gsap } from "gsap";
import { HiOutlineBars3 } from "react-icons/hi2";
import { Box, Drawer } from "@mui/material";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

function Header({
  setOpenContact,
  setOpenRequest,
  selectedLanguage,
  handleLanguageChange,
  anchor,
}) {
  const [openMenu, setOpenMenu] = useState(false);
  const [background, setBackground] = useState(true);
  const location = useLocation();

  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      gsap.from(".logo", { opacity: 0, duration: 1, delay: 1, y: 10 });
      gsap.from(".nav-item", {
        opacity: 0,
        duration: 1,
        delay: 2,
        y: 40,
        stagger: 0.3,
      });
      gsap.from(".languages", { opacity: 0, duration: 1, delay: 2.8, y: 10 });
      gsap.from(".btn-action", { opacity: 0, duration: 1, delay: 3.2, y: 10 });
    });

    return () => ctx.revert();
  }, []);

  useEffect(() => {
    if (
      location.pathname === "/" ||
      location.pathname === "/links/" ||
      location.pathname === "/links"
    ) {
      setBackground(true);
    } else {
      setBackground(false);
    }
  }, [location.pathname]);

  return (
    <header
      className="header"
      style={{
        background: background ? "#ffffff00" : "#ffffff",
      }}
    >
      <nav className="nav container">
        <Link to="/" className="logo">
          <img src={logo} alt="logo" />
        </Link>
        <div className="nav-center">
          <NavList setOpenContact={setOpenContact} setOpenMenu={setOpenMenu} />
        </div>
        <NavRight
          setOpenRequest={setOpenRequest}
          selectedLanguage={selectedLanguage}
          handleLanguageChange={handleLanguageChange}
        />
        <HiOutlineBars3
          className="menu-container"
          onClick={() => setOpenMenu(true)}
        />
        <Drawer
          open={openMenu}
          onClose={() => setOpenMenu(false)}
          anchor={anchor}
        >
          <Box
            sx={{ width: 350 }}
            role="presentation"
            onKeyDown={() => setOpenMenu(false)}
            className="nav-box"
          >
            <NavList
              setOpenContact={setOpenContact}
              setOpenMenu={setOpenMenu}
            />
            <NavRight
              setOpenRequest={setOpenRequest}
              selectedLanguage={selectedLanguage}
              handleLanguageChange={handleLanguageChange}
            />
          </Box>
        </Drawer>
      </nav>
    </header>
  );
}

export default Header;
