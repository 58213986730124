import React from "react";
import ServiceBox from "./ServiceBox";
import { useTranslation } from "react-i18next";

function ServicesTwo() {
  const [t] = useTranslation();

  return (
    <div className="services-two" id="services">
      <div className="container">
        <div className="services-header">
          <h6>{t("Our Services")}</h6>
          <h1>
            {t("Elevate Your Online Presence")}
            <br />
            {t("With Our Comprehensive Social")}
            <br />
            {t("Media Services")}
          </h1>
        </div>
        <div className="services-boxes">
          <ServiceBox
            id={"01"}
            title={t("Branding")}
            text={t(
              "Define your brand with a standout visual identity logo, colors, typography."
            )}
            duration={1000}
          />
          <ServiceBox
            id={"02"}
            title={t("Marketing")}
            text={t(
              "Craft a dynamic marketing approach with strategies spanning offline and online realms."
            )}
            duration={2000}
          />
          <ServiceBox
            id={"03"}
            title={t("Development")}
            text={t(
              "Fuel growth with robust development solutions tailored to your needs."
            )}
            duration={3000}
          />
          <ServiceBox
            id={"04"}
            title={t("Shooting")}
            text={t("Capture the moment with precision and creativity.")}
            duration={1000}
          />
          <ServiceBox
            id={"05"}
            title={t("Social Media Monitoring")}
            text={t(
              "Stay ahead in the digital landscape with our comprehensive social media monitoring services."
            )}
            duration={2000}
          />
          <ServiceBox
            id={"06"}
            title={t("Content Creation")}
            text={t(
              "Ignite your brand with compelling content tailored to captivate your audience."
            )}
            duration={3000}
          />
        </div>
      </div>
    </div>
  );
}

export default ServicesTwo;
