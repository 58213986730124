import React from "react";
import { useTranslation } from "react-i18next";

function ContactUs({ setOpenContact }) {
  const [t] = useTranslation();

  return (
    <div className="contact-us">
      <div className="container">
        <div className="contact">
          <h1>{t("Get Custom Solution To Your Social Media Audiance!")}</h1>
          <button onClick={() => setOpenContact(true)} className="contact-btn">
            {t("Contact With Us")}
          </button>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
