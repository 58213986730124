import Swal from "sweetalert2";

const submitForm = async (url, t, formData, setOpen) => {
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    });

    const data = await response.json();

    if (data.success) {
      Swal.fire({
        icon: "success",
        title: t("Form submitted successfully"),
        showConfirmButton: false,
        timer: 2500,
      });
      if (setOpen) {
        setOpen(false);
      }
    } else {
      Swal.fire({
        title: t("Error!"),
        html: `${t(
          "Submission of the form was unsuccessful. Please reach out to us for assistance at "
        )}<a href="mailto:support@goup.ma" class="swal-link">support@goup.ma</a>.
          `,
        icon: "error",
        confirmButtonText: t("Close"),
      });
    }
  } catch (error) {
    Swal.fire({
      title: t("Error!"),
      html: `${t(
        "Submission of the form was unsuccessful. Please reach out to us for assistance at "
      )}<a href="mailto:support@goup.ma" class="swal-link">support@goup.ma</a>.
        `,
      icon: "error",
      confirmButtonText: t("Close"),
    });
  }
};

export default submitForm;
