import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

import i18next from "i18next";
import { I18nextProvider } from "react-i18next";
import enTranslation from "./translations/en.json";
import frTranslation from "./translations/fr.json";
import arTranslation from "./translations/ar.json";

const langs = ["en", "fr", "ar"];
let userLang = navigator.language || navigator.userLanguage || langs[0];

userLang = langs.includes(userLang.slice(0, 2)) ? userLang.slice(0, 2) : "en";

i18next.init({
  resources: {
    en: { translation: enTranslation },
    fr: { translation: frTranslation },
    ar: { translation: arTranslation },
  },
  lng: userLang,
  fallbackLng: userLang,
  interpolation: {
    escapeValue: false,
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <App userLang={userLang} />
    </I18nextProvider>
  </React.StrictMode>
);
