import { useState } from "react";
import { TextField } from "@mui/material";
import { IoMdClose } from "react-icons/io";
import { useTranslation } from "react-i18next";
import submitForm from "../../apiUtils";

function RequestForm({ openRequest, setOpenRequest }) {
  const [t] = useTranslation();
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    phone_number: "",
    needs: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    await submitForm(
      `${window.location.origin}/submit_request_form/`,
      t,
      formData,
      setOpenRequest
    );
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  if (openRequest) {
    return (
      <form
        onSubmit={handleSubmit}
        className="form"
        onClick={() => setOpenRequest(false)}
      >
        <div className="form-container" onClick={(e) => e.stopPropagation()}>
          <span className="close-btn" onClick={() => setOpenRequest(false)}>
            <IoMdClose />
          </span>
          <h1>{t("Fill out the form so we can contact you!")}</h1>
          <div className="full-name">
            <TextField
              label={t("First Name")}
              required
              variant="outlined"
              className="input-name"
              name="first_name"
              onChange={handleChange}
            />
            <TextField
              label={t("Second Name")}
              required
              variant="outlined"
              className="input-name"
              name="last_name"
              onChange={handleChange}
            />
          </div>
          <TextField
            label={t("Phone Number")}
            required
            variant="outlined"
            type="tel"
            name="phone_number"
            onChange={handleChange}
          />
          <TextField
            multiline
            label={t("Your Needs")}
            required
            variant="outlined"
            rows={4}
            name="needs"
            onChange={handleChange}
          />
          <div className="send-btn">
            <button>{t("Send")}</button>
          </div>
        </div>
      </form>
    );
  }
}

export default RequestForm;
