import { useState, useEffect, useRef } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useTranslation } from "react-i18next";

import "./Assets/css/style.css";
import "aos/dist/aos.css";

import ScrollToTop from "./ScrollToTop";
import ContactForm from "./Components/ContactForm/ContactForm";
import RequestForm from "./Components/RequestForm/RequestForm";
import Header from "./Components/Header/Header";
import Home from "./Components/Home/Home";
import Footer from "./Components/Footer/Footer";
import About from "./Components/About/About";
import TermsAndConditions from "./Components/TermsAndConditions/TermsAndConditions";
import PrivacyPolicy from "./Components/PrivacyPolicy/PrivacyPolicy";
import SocialMediaLinks from "./Components/SocialMediaLinks/SocialMediaLinks";

function App({ userLang }) {
  const [openContact, setOpenContact] = useState(false);
  const [openRequest, setOpenRequest] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(
    getCookie("selectedLanguage") || userLang
  );
  const { i18n } = useTranslation();
  const i18nRef = useRef(i18n.current);
  const [anchor, setAnchor] = useState("right");

  useEffect(() => {
    i18nRef.current = i18n;
  }, [i18n]);

  const setLanguageAndDirection = (language) => {
    setSelectedLanguage(language);
    i18nRef.current.changeLanguage(language);
    document.body.dir = language === "ar" ? "rtl" : "ltr";
    setCookie("selectedLanguage", language, 30);
    const anchor = document.body.dir === "rtl" ? "left" : "right";
    setAnchor(anchor);
  };

  const handleLanguageChange = (event) => {
    setLanguageAndDirection(event.target.value);
  };

  useEffect(() => {
    const storedLanguage = getCookie("selectedLanguage") || userLang;
    if (storedLanguage) {
      setLanguageAndDirection(storedLanguage);
    }
  }, [userLang]);

  return (
    <Router scrollRestoration="manual">
      <ScrollToTop />
      <div className="App">
        <Header
          setOpenContact={setOpenContact}
          setOpenRequest={setOpenRequest}
          selectedLanguage={selectedLanguage}
          handleLanguageChange={handleLanguageChange}
          anchor={anchor}
        />
        <Routes>
          <Route path="/links" element={<SocialMediaLinks />} />
          <Route path="/about" element={<About />} />
          <Route
            path="/terms-and-conditions"
            element={<TermsAndConditions />}
          />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route
            path="/"
            element={
              <Home
                setOpenContact={setOpenContact}
                setOpenRequest={setOpenRequest}
              />
            }
          />
        </Routes>
        <ContactForm
          openContact={openContact}
          setOpenContact={setOpenContact}
        />
        <RequestForm
          openRequest={openRequest}
          setOpenRequest={setOpenRequest}
        />

        <Footer setOpenContact={setOpenContact} />
      </div>
    </Router>
  );
}

export default App;

const setCookie = (name, value, days) => {
  const expirationDate = new Date();
  expirationDate.setTime(expirationDate.getTime() + days * 24 * 60 * 60 * 1000);
  const expires = `expires=${expirationDate.toUTCString()}`;
  document.cookie = `${name}=${value};${expires};path=/`;
};

const getCookie = (name) => {
  const cookies = document.cookie.split(";");
  for (let cookie of cookies) {
    const [cookieName, cookieValue] = cookie.split("=");
    if (cookieName.trim() === name) {
      return cookieValue;
    }
  }
  return null;
};
