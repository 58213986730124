import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

function NavList({ setOpenContact, setOpenMenu }) {
  const [t] = useTranslation();

  return (
    <ul className="nav-list" onClick={() => setOpenMenu(false)}>
      <li className="nav-item active">
        <Link to="/" className="nav-link">
          {t("Home")}
        </Link>
      </li>
      <li className="nav-item">
        <HashLink to="/#about" className="nav-link">
          {t("About")}
        </HashLink>
      </li>
      <li className="nav-item">
        <a
          href="#contact"
          onClick={() => setOpenContact(true)}
          className="nav-link"
        >
          {t("Contact")}
        </a>
      </li>
      <li className="nav-item">
        <HashLink to="/#services" className="nav-link">
          {t("Services")}
        </HashLink>
      </li>
    </ul>
  );
}

export default NavList;
