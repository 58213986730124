import React from "react";
import AOS from "aos";
function ServiceOneDetail({ icon, title, text }) {
  AOS.init()

  return (
    <div className="service-one-detail" data-aos="fade-up" data-aos-duration="3000">
      <div className="service-icon">{icon}</div>
      <div className="service-detail">
        <h2 className="service-title">{title}</h2>
        <p className="service-text">{text}</p>
      </div>
    </div>
  );
}

export default ServiceOneDetail;
