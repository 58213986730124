import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

function TermsAndConditions() {
  const [t] = useTranslation();
  useEffect(() => {
    document.title = t("GoUp - Terms And Conditions");
  }, [t]);

  return (
    <div className="page">
      <div className="container-page">
        <h1>{t("Welcome to GoUp!")}</h1>

        <section>
          <h2>{t("Introduction")}</h2>
          <p>
            {t(
              "By accessing and using our services, you agree to comply with and be bound by the following terms and conditions. Please read these terms carefully before using our services."
            )}
          </p>
        </section>

        <section>
          <h2>{t("Service Description")}</h2>
          <p>
            {t(
              "GoUp provides social media marketing services, including but not limited to social media management, content creation, analytics, and strategy development. By engaging with our services, you agree to abide by the terms outlined in this agreement."
            )}
          </p>
        </section>

        <section>
          <h2>{t("Payment Terms")}</h2>
          <p>
            {t(
              "Clients are required to pay for services as outlined in the agreed-upon contract. Payment terms, including invoicing details, are specified in the agreement. Failure to adhere to payment terms may result in the suspension of services."
            )}
          </p>
        </section>

        <section>
          <h2>{t("Client Responsibilities")}</h2>
          <p>
            {t(
              "Clients are responsible for providing necessary information, approvals, and access to social media accounts as required for the provision of services. Timely collaboration is essential for the success of the engagement."
            )}
          </p>
        </section>

        <section>
          <h2>{t("Intellectual Property")}</h2>
          <p>
            {t(
              "All intellectual property rights associated with content created by GoUp during the course of providing services shall be owned by the client upon successful payment for those services."
            )}
          </p>
        </section>
      </div>
    </div>
  );
}

export default TermsAndConditions;
