import { useState } from "react";
import { TextField } from "@mui/material";
import { IoMdClose } from "react-icons/io";
import { useTranslation } from "react-i18next";
import submitForm from "../../apiUtils";

function ContactForm({ openContact, setOpenContact }) {
  const [t] = useTranslation();
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    message: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    await submitForm(
      `${window.location.origin}/submit_contact_form/`,
      t,
      formData,
      setOpenContact
    );
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  if (openContact)
    return (
      <form
        onSubmit={handleSubmit}
        className="form"
        onClick={() => setOpenContact(false)}
      >
        <div className="form-container" onClick={(e) => e.stopPropagation()}>
          <span className="close-btn" onClick={() => setOpenContact(false)}>
            <IoMdClose />
          </span>
          <h1>{t("Get In Touch With Us")}</h1>
          <div className="full-name">
            <TextField
              label={t("First Name")}
              required
              variant="outlined"
              className="input-name"
              name="first_name"
              onChange={handleChange}
            />
            <TextField
              label={t("Second Name")}
              required
              variant="outlined"
              className="input-name"
              name="last_name"
              onChange={handleChange}
            />
          </div>
          <TextField
            label={t("Email")}
            required
            variant="outlined"
            type="email"
            name="email"
            onChange={handleChange}
          />
          <TextField
            multiline
            label={t("Message")}
            required
            variant="outlined"
            rows={4}
            name="message"
            onChange={handleChange}
          />
          <div className="send-btn">
            <button>{t("Send")}</button>
          </div>
        </div>
      </form>
    );
}

export default ContactForm;
