import React from "react";
import AOS from 'aos'

function ServiceBox({ id, title, text, duration }) {
  AOS.init()

  return (
    <div className={`service-box service-box-${id}`} data-aos="fade-up-right" data-aos-duration={duration}>
      <span>{id}</span>
      <div className="service-content">
        <h2>{title}</h2>
        <p>{text}</p>
      </div>
    </div>
  );
}

export default ServiceBox;
