import React, { useEffect } from "react";
import {
  BsFacebook,
  BsInstagram,
  BsLinkedin,
  BsWhatsapp,
  BsYoutube,
} from "react-icons/bs";
import { RiTwitterXFill } from "react-icons/ri";
import { GoArrowUpRight } from "react-icons/go";
import { FaPhone } from "react-icons/fa6";

import { useTranslation } from "react-i18next";
import AOS from "aos";

function SocialMediaLinks() {
  AOS.init();

  const [t] = useTranslation();

  useEffect(() => {
    document.title = t("GoUp - Social Media Links");
  }, [t]);

  return (
    <div className="social-media-links">
      <h1>Welcome</h1>
      <a
        className="social-media instagram"
        href="https://www.instagram.com/goupma"
        target="_blank"
        rel="noopener noreferrer"
        data-aos="fade-up"
        data-aos-anchor-placement="top-bottom"
        data-aos-duration="1300"
      >
        <div className="social-logo">
          <BsInstagram />
        </div>
        <div className="social-title">{t("Instagram")}</div>
        <div className="icon">
          <GoArrowUpRight />
        </div>
      </a>
      <a
        className="social-media facebook"
        href="https://www.facebook.com/profile.php?id=61550682847495"
        target="_blank"
        rel="noopener noreferrer"
        data-aos="fade-up"
        data-aos-anchor-placement="top-bottom"
        data-aos-duration="1500"
      >
        <div className="social-logo">
          <BsFacebook />
        </div>
        <div className="social-title">{t("Facebook")}</div>
        <div className="icon">
          <GoArrowUpRight />
        </div>
      </a>
      <a
        className="social-media whatsapp"
        href="https://api.whatsapp.com/send?phone=%2B212664900805"
        target="_blank"
        rel="noopener noreferrer"
        data-aos="fade-up"
        data-aos-anchor-placement="top-bottom"
        data-aos-duration="2000"
      >
        <div className="social-logo">
          <BsWhatsapp />
        </div>
        <div className="social-title">{t("Whatsapp 1")}</div>
        <div className="icon">
          <GoArrowUpRight />
        </div>
      </a>
      <a
        className="social-media whatsapp"
        href="https://api.whatsapp.com/send?phone=%2B212664901343"
        target="_blank"
        rel="noopener noreferrer"
        data-aos="fade-up"
        data-aos-anchor-placement="top-bottom"
        data-aos-duration="2000"
      >
        <div className="social-logo">
          <BsWhatsapp />
        </div>
        <div className="social-title">{t("Whatsapp 2")}</div>
        <div className="icon">
          <GoArrowUpRight />
        </div>
      </a>
      <a
        className="social-media linkdin"
        href="https://www.linkedin.com/company/goupma"
        target="_blank"
        rel="noopener noreferrer"
        data-aos="fade-up"
        data-aos-anchor-placement="top-bottom"
        data-aos-duration="2500"
      >
        <div className="social-logo">
          <BsLinkedin />
        </div>
        <div className="social-title">{t("Linkdin")}</div>
        <div className="icon">
          <GoArrowUpRight />
        </div>
      </a>
      <a
        className="social-media youtube"
        href="https://www.youtube.com/@goupma"
        target="_blank"
        rel="noopener noreferrer"
        data-aos="fade-up"
        data-aos-anchor-placement="top-bottom"
        data-aos-duration="2700"
      >
        <div className="social-logo">
          <BsYoutube />
        </div>
        <div className="social-title">{t("Youtube")}</div>
        <div className="icon">
          <GoArrowUpRight />
        </div>
      </a>
      <a
        className="social-media xtwitter"
        href="https://twitter.com/GoUpMa"
        target="_blank"
        rel="noopener noreferrer"
        data-aos="fade-up"
        data-aos-anchor-placement="top-bottom"
        data-aos-duration="2900"
      >
        <div className="social-logo">
          <RiTwitterXFill />
        </div>
        <div className="social-title">{t("X")}</div>
        <div className="icon">
          <GoArrowUpRight />
        </div>
      </a>
      <a
        className="social-media phone"
        href="tel:+212664900805"
        data-aos="fade-up"
        data-aos-anchor-placement="top-bottom"
        data-aos-duration="3000"
      >
        <div className="social-logo">
          <FaPhone />
        </div>
        <div className="social-title">{t("Call Us")}</div>
        <div className="icon">
          <GoArrowUpRight />
        </div>
      </a>
    </div>
  );
}

export default SocialMediaLinks;
