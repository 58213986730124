import React from "react";
import AOS from "aos";

function Intergration({ icon, duration }) {
  AOS.init();

  return (
    <div
      className="integration"
      data-aos="fade-right"
      data-aos-easing="linear"
      data-aos-duration={duration}
    >
      <div className="integration-icon">{icon}</div>
      <div className="shadow-icon">{icon}</div>
    </div>
  );
}

export default Intergration;
