import React from "react";
import AOS from "aos";

function Feature({ featureName, icon, iconId, title, p }) {
  AOS.init();
  return (
    <div
      className={`feature ${featureName}`}
      data-aos="fade-up"
      data-aos-anchor-placement="top-bottom"
      data-aos-duration="2500"
    >
      <div className={`feature-icon feature-icon-${iconId}`}>{icon}</div>
      <div className="feature-text">
        <h3 className="feature-title">{title}</h3>
        <p className="feature-p">{p}</p>
      </div>
    </div>
  );
}

export default Feature;
