import React from "react";
import { FormControl, Select, MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";
import arFlag from "../../Assets/img/flags/ar.png";
import enFlag from "../../Assets/img/flags/en.png";
import frFlag from "../../Assets/img/flags/fr.svg";

function NavRight({ setOpenRequest, selectedLanguage, handleLanguageChange }) {
  const [t] = useTranslation();

  return (
    <div className="nav-right">
      <FormControl className="languages">
        <Select
          id="language-select"
          value={selectedLanguage}
          onChange={handleLanguageChange}
        >
          {languages.map((lang) => (
            <MenuItem
              key={lang.value}
              value={lang.value}
              className="menu-language"
            >
              <img src={lang.flag} alt={lang.value} className="flag" />
              <span>{lang.label}</span>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <button className="btn-action" onClick={() => setOpenRequest(true)}>
        {t("Get A Quote")}
      </button>
    </div>
  );
}

export default NavRight;

const languages = [
  { value: "en", label: "English", flag: enFlag },
  { value: "fr", label: "Français", flag: frFlag },
  { value: "ar", label: "العربية", flag: arFlag },
];
