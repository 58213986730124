import { useState } from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import submitForm from "../../apiUtils";

import logo from "../../Assets/img/logo-250.png";
import {
  BsFacebook,
  BsInstagram,
  BsLinkedin,
  BsWhatsapp,
} from "react-icons/bs";
import { RiTwitterXFill } from "react-icons/ri";
import { useTranslation } from "react-i18next";

function Footer({ setOpenContact }) {
  const [t] = useTranslation();
  const currentYear = new Date().getFullYear();
  const [formData, setFormData] = useState({ email: "" });

  const handleSubmit = async (e) => {
    e.preventDefault();
    await submitForm(
      `${window.location.origin}/submit_email_form/`,
      t,
      formData
    );
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div className="footer">
      <div className="left-footer">
        <h2>{t("Your business is safe with us at GoUp")}</h2>
        <p>
          {t(
            "Rest easy knowing your business is in secure hands. Our commitment to safety ensures your peace of mind and success."
          )}
        </p>
        <button className="btn-contact" onClick={() => setOpenContact(true)}>
          {t("Contact Us")}
        </button>
      </div>
      <div className="right-footer">
        <div className="top">
          <div className="email-part">
            <img src={logo} alt="logo" />
            <p>
              {t(
                "At our agency, we believe that every brand has a unique story to tell"
              )}
            </p>
          </div>
          <div className="menu-part">
            <ul>
              <li>
                <Link to="/">{t("Home")}</Link>
              </li>
              <li>
                <HashLink to="/#about" className="nav-link">
                  {t("About")}
                </HashLink>
              </li>
              <li>
                <a href="#contact" onClick={() => setOpenContact(true)}>
                  {t("Contact")}
                </a>
              </li>
              <li>
                <HashLink to="/#services" className="nav-link">
                  {t("Services")}
                </HashLink>
              </li>
            </ul>
          </div>
          <div className="menu-part">
            <ul>
              <li>
                <Link to="/about">{t("About Us")}</Link>
              </li>
              <li>
                <Link to="/terms-and-conditions">
                  {t("Terms And Conditions")}
                </Link>
              </li>
              <li>
                <Link to="/privacy-policy">{t("Privacy Policy")}</Link>
              </li>
            </ul>
          </div>
          <div className="social-part">
            <ul>
              <li>
                <a
                  href="https://www.instagram.com/goupma"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <BsInstagram />
                </a>
              </li>
              <li>
                <a
                  href="https://www.facebook.com/profile.php?id=61550682847495"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <BsFacebook />
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/goupma"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <BsLinkedin />
                </a>
              </li>
              <li>
                <a
                  href="https://twitter.com/GoUpMa"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <RiTwitterXFill />
                </a>
              </li>
              <li>
                <a
                  href="https://api.whatsapp.com/send?phone=%2B212664900805"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <BsWhatsapp />
                </a>
              </li>
              <li>
                <a
                  href="https://api.whatsapp.com/send?phone=%2B212664901343"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <BsWhatsapp />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="bottom">
          <form onSubmit={handleSubmit}>
            <input
              name="email"
              type="text"
              placeholder={t("Your Email")}
              required
              autoComplete="email"
              onChange={handleChange}
            />
            <button>{t("Send")}</button>
          </form>
          <div className="copyright" dir="ltr">
            &copy; {currentYear} <Link to="/">GoUp</Link>,{" "}
            {t("All rights reserved.")}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
