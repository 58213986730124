import React from "react";
import ServiceOneDetail from "./ServiceOneDetail";
import { BsCheck2All, BsSend, BsCamera2 } from "react-icons/bs";
import { FaRegFlag, FaRegLightbulb } from "react-icons/fa";
import { HiOutlinePuzzlePiece } from "react-icons/hi2";
import { useTranslation } from "react-i18next";

function ServicesOne() {
  const [t] = useTranslation();

  return (
    <div className="services-one" id="work">
      <div className="container">
        <h1 className="title">{t("We Help You To Gain Audience And Sales")}</h1>
        <p className="text">
          {t(
            "Boost audience and sales with our expertise. From targeted audience engagement to irresistible brand identities,"
          )}
          <br />
          {t("we're here to help you succeed.")}
        </p>
        <div className="services-one-group">
          <div className="services-one-details">
            <ServiceOneDetail
              icon={<BsCheck2All />}
              title={t("Specific Audience Targeting")}
              text={t(
                "Precision is key. We specialize in reaching your specific audience, ensuring your message resonates with those who matter most to your business."
              )}
            />
            <ServiceOneDetail
              icon={<BsSend />}
              title={t("Reaching New Customers")}
              text={t(
                "Expand your reach and connect with untapped markets. Our strategies are designed to attract and engage new customers, fostering continuous growth."
              )}
            />
            <ServiceOneDetail
              icon={<FaRegFlag />}
              title={t("Social Media Advertising")}
              text={t(
                "Maximize your online presence with strategic social media advertising. We leverage the power of platforms to enhance visibility and engage your target audience effectively."
              )}
            />
          </div>
          <div className="services-one-details">
            <ServiceOneDetail
              icon={<HiOutlinePuzzlePiece />}
              title={t("Crafting an Irresistible Brand Identity")}
              text={t(
                "Stand out in a crowded market with a compelling brand identity. We work with you to create a unique and irresistible brand persona that captivates your audience."
              )}
            />
            <ServiceOneDetail
              icon={<FaRegLightbulb />}
              title={t("Turning Ideas into Powerful Digital Experiences")}
              text={t(
                "Innovation is at the core of what we do. Transform your ideas into impactful digital experiences that leave a lasting impression on your audience."
              )}
            />
            <ServiceOneDetail
              icon={<BsCamera2 />}
              title={t("High-Quality Visual Content Creation")}
              text={t(
                "Visuals speak louder than words. Our team excels in crafting high-quality visual content that not only grabs attention but also communicates your brand's story with clarity and creativity."
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ServicesOne;
