import React, { useEffect } from "react";
import Feature from "./Feature";
import StatisticBox from "./StatisticBox";
import MainFeatureImg from "../../../Assets/img/main-features.png";
import FeatureImgBox from "../../../Assets/img/main-features-box.png";
import { TbMoneybag } from "react-icons/tb";
import { MdOutlineAddBusiness, MdReviews } from "react-icons/md";
import { FcOnlineSupport } from "react-icons/fc";
import { BiSolidUser } from "react-icons/bi";
import { AiFillHeart } from "react-icons/ai";
import { gsap } from "gsap";
import AOS from "aos";
import { useTranslation } from "react-i18next";

function MainFeatures() {
  const [t] = useTranslation();
  AOS.init();

  useEffect(() => {
    gsap.to(".statistic-box-1", 2.3, {
      y: "+=10px",
      yoyo: true,
      repeat: -1,
      ease: gsap.easeOut,
    });
    gsap.to(".statistic-box-2", 2, {
      y: "+=10px",
      yoyo: true,
      repeat: -1,
      ease: gsap.easeOut,
    });
    gsap.to(".statistic-box-3", 2.5, {
      y: "+=10px",
      yoyo: true,
      repeat: -1,
      ease: gsap.easeOut,
    });
    gsap.to(".statistic-box-4", 2.2, {
      y: "+=10px",
      yoyo: true,
      repeat: -1,
      ease: gsap.easeOut,
    });
  });

  return (
    <div className="main-features" id="about">
      <div className="container">
        <h1 className="title">{t("Features That Attract Our Visitors")}</h1>
        <p className="text">
          {t(
            "We are the best social media marketing agency all over the world. We"
          )}
          <br />
          {t("try to provide the amazing services to our clients.")}
        </p>
        <div className="statistic">
          <div
            className="statistic-img"
            data-aos="fade-right"
            data-aos-offset="300"
            data-aos-duration="1000"
            data-aos-easing="ease-in-sine"
          >
            <img src={MainFeatureImg} alt="main-features" />
            <StatisticBox
              boxName={"statistic-box-1"}
              icon={<BiSolidUser />}
              title={"20,124"}
              text_0={t("2000 People visits on")}
              text_1={t("website per day")}
            />
            <StatisticBox
              boxName={"statistic-box-2"}
              icon={<AiFillHeart />}
              title={"1,10,124"}
              text_0={t("100k People have seen our last post")}
            />
            <StatisticBox
              boxName={"statistic-box-3"}
              icon={<MdReviews />}
              title={t("5k Reviews")}
              text_0={t("More than 5k people have given")}
              text_1={t("reviews to our last post")}
            />
            <img
              src={FeatureImgBox}
              alt="statistic-box"
              className="statistic-box statistic-box-4"
            />
          </div>
          <div className="statistic-details">
            <Feature
              featureName={"featureOne"}
              icon={<TbMoneybag />}
              iconId={1}
              title={t("Marketing & Sales")}
              p={t(
                "Elevate your brand and expand your reach with our comprehensive marketing and sales solutions."
              )}
            />
            <Feature
              featureName={"featureTwo"}
              icon={<MdOutlineAddBusiness />}
              iconId={2}
              title={t("Boosting Your Business")}
              p={t(
                "Unlock the full potential of your enterprise with our innovative business-boosting strategies."
              )}
            />
            <Feature
              featureName={"featureThree"}
              icon={<FcOnlineSupport />}
              iconId={3}
              title={t("Any time online Support")}
              p={t(
                "Our commitment to your success extends beyond traditional business hours."
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default MainFeatures;
