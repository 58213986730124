import React, { useLayoutEffect, useEffect, useRef } from "react";
// import MokaMain from "../../../Assets/img/moka-main.png";
import { gsap } from "gsap";
import RocketIcon from "@mui/icons-material/Rocket";
import { useTranslation } from "react-i18next";
import Lottie from "lottie-react";
import PenTool from "../../../Assets/img/pen-tool.png";
import MokaMain from "../../../Assets/json/moka.json";

function Top({ setOpenRequest }) {
  const mokaRef = useRef();

  const [t] = useTranslation();
  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      gsap.from(".moka-title", { opacity: 0, duration: 1, delay: 1.6, y: 30 });
      gsap.from(".moka-desp", { opacity: 0, duration: 1, delay: 1.8, y: 30 });
      gsap.from(".start-btn", { opacity: 0, duration: 1, delay: 2.1, y: 30 });
      gsap.from(".how-btn", { opacity: 0, duration: 1, delay: 2.2, y: 30 });
      gsap.from(".moka-detail", { opacity: 0, duration: 1, delay: 0.3, y: 30 });
      gsap.from(".rocket", { opacity: 0, duration: 1, delay: 1.5, y: 30 });
    });

    return () => ctx.revert();
  }, []);

  useEffect(() => {
    gsap.to(".rocket-icon", 1.3, {
      y: "+=10px",
      yoyo: true,
      repeat: -1,
      ease: gsap.easeOut,
    });
  });

  return (
    <div className="home">
      <div className="home-container container">
        <div className="moka">
          <h1 className="moka-title">
            <span className="highlight">{t("We")}</span> {t("Are Solution")}{" "}
            <br />
            {t("Oriented")}{" "}
            <span className="pen-tool">
              {t("Digital")}{" "}
              <img src={PenTool} alt="pen-tool" draggable="false" />
            </span>{" "}
            <br />
            {t("Agency.")}
          </h1>
          <p className="moka-desp">
            {t(
              "committed to driving innovation and achieving tangible results"
            )}
            <br />
            {t("for our clients.")}
          </p>

          <div className="btn-group">
            <a
              href="#id"
              onClick={() => setOpenRequest(true)}
              className="home-btn start-btn"
            >
              {t("Get Started")}
            </a>
            <a href="#work" className="home-btn how-btn">
              {t("How it Works")}
            </a>
          </div>
        </div>
        <div className="moka-detail">
          {/*
          <span className="bg"></span>
          <span className="bg"></span>
          <span className="bg"></span>
          <span className="bg"></span>
          */}

          <div className="player">
            <Lottie
              onComplete={() => {
                mokaRef.current.goToAndPlay(96, true);
              }}
              loop={false}
              lottieRef={mokaRef}
              animationData={MokaMain}
            />
            {/*<img src={MokaMain} alt="moka" />*/}
          </div>
        </div>
      </div>
      <div className="rocket">
        <a href="#tool-we-use">
          <RocketIcon className="rocket-icon" />
        </a>
      </div>
    </div>
  );
}

export default Top;
