import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

function About() {
  const [t] = useTranslation();
  useEffect(() => {
    document.title = t("GoUp - About Us");
  }, [t]);

  return (
    <div className="page">
      <div className="container-page">
        <h1>{t("Empowering Brands through Social Media Excellence")}</h1>

        <section>
          <h2>{t("Introduction")}</h2>
          <p>
            {t(
              "At GoUp, we believe in the transformative power of social media. Our passion is helping businesses establish and expand their online presence through strategic and creative social media marketing campaigns."
            )}
          </p>
        </section>

        <section>
          <h2>{t("Our Mission")}</h2>
          <p>
            {t(
              "Empower businesses to build meaningful relationships, drive brand awareness, and achieve unprecedented success through strategic and innovative social media marketing solutions."
            )}
          </p>
        </section>

        <section>
          <h2>{t("What Sets Us Apart")}</h2>
          <p>
            {t(
              "Our team comprises seasoned experts who stay ahead of the ever-evolving social media landscape, ensuring your brand remains at the forefront of industry trends. We understand that every business is unique. That's why we craft customized strategies that align with your brand's personality, goals, and target audience. We are committed to delivering tangible results through a data-driven approach."
            )}
          </p>
        </section>

        <section>
          <h2>{t("Meet the Team")}</h2>
          <p>
            {t(
              "Our team is a diverse group of professionals with expertise in social media strategy, content creation, analytics, and more. Together, we work collaboratively to bring your brand to new heights."
            )}
          </p>
        </section>

        <section>
          <h2>{t("Client Testimonials")}</h2>
          <p>
            "
            {t(
              "Working with GoUp has been a game-changer for our business. Their strategic approach and creative content have significantly boosted our online presence and engagement."
            )}
            "
          </p>
          <p>
            "
            {t(
              "The team at GoUp is not just a service provider; they are true partners in our success. Their dedication and results-driven mindset make them stand out in the industry."
            )}
            "
          </p>
        </section>
      </div>
    </div>
  );
}

export default About;
