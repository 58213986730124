import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

function PrivacyPolicy() {
  const [t] = useTranslation();
  useEffect(() => {
    document.title = t("GoUp - Privacy Policy");
  }, [t]);

  return (
    <div className="page">
      <div className="container-page">
        <h1>{t("Privacy Policy")}</h1>

        <section>
          <h2>{t("Information Collection")}</h2>
          <p>
            {t(
              "GoUp collects information from clients for the purpose of providing social media marketing services. This includes personal data such as names, contact information, and social media account details."
            )}
          </p>
        </section>

        <section>
          <h2>{t("Data Security")}</h2>
          <p>
            {t(
              "GoUp employs industry-standard security measures to protect the confidentiality and integrity of client information. Access to client data is restricted to authorized personnel only."
            )}
          </p>
        </section>

        <section>
          <h2>{t("Third-Party Disclosure")}</h2>
          <p>
            {t(
              "GoUp does not disclose client information to third parties without explicit consent unless required by law. In some cases, third-party tools may be used for analytics or other services, and clients will be informed accordingly."
            )}
          </p>
        </section>

        <section>
          <h2>{t("Cookies and Tracking")}</h2>
          <p>
            {t(
              "GoUp uses cookies and tracking technologies to enhance user experience and gather information about website usage. Users can manage cookie preferences through their browser settings."
            )}
          </p>
        </section>

        <section>
          <h2>{t("Privacy Rights")}</h2>
          <p>
            {t(
              "Users have the right to access, correct, or delete their personal information held by GoUp. Requests related to privacy rights can be submitted through the contact information provided on the website."
            )}
          </p>
        </section>

        <section>
          <h2>{t("Updates to Privacy Policy")}</h2>
          <p>
            {t(
              "GoUp reserves the right to update this privacy policy to reflect changes in data protection regulations or our services. Users will be notified of any significant changes through the website or other appropriate channels."
            )}
          </p>
        </section>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
